<template>
    <div class="flex flex-row flex-nowrap justify-around gap-10 transition-opacity duration-500" v-bind:class="{ 'visible opacity-100' : active, 'invisible opacity-50': !active }">
        <div v-for="n in parseInt(steps)" v-bind:key="n"
            class="w-6 h-6 text-center rounded-full transition-transform duration-500 delay-200 ease-over-out"
            v-bind:class="{
                'text-white scale-110 bg-primary' : parseInt(current) >= n && active,
                'text-gray-300 scale-75 bg-transparent' : parseInt(current) < n || !active,
            }">
            {{ n }}
        </div>
    </div>
</template>
<script setup>
defineProps({
    steps: { default: 1 },
    current: { default: 1 },
})
const active = ref(false)
onMounted(function(){
    setTimeout(function(){ active.value = true }, 100)
})
onBeforeUnmount(() => active.value = false )
</script>